<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row class="p-1">
        <b-col>
          Search phrase: <strong>{{ query }}</strong>
        </b-col>
      </b-row>

      <b-row class="p-1 text-center" v-if="totalResultsCount === 0">
        <b-col class="p-3">
          No data available
        </b-col>
      </b-row>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('accounts').visible"
      >
        <div key="accounts-results" v-show="accounts.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="accounts.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-accounts
                style="display: inline;cursor: pointer"
              >
                Accounts
                <span v-if="accounts.count > 0">({{ accounts.count }})</span>
              </h5>
            </b-col>
          </b-row>
          <b-collapse visible id="collapse-accounts" v-model="accounts.visible">
            <b-row>
              <b-col>
                <accounts-table
                  ref="accounts-table"
                  :per-page="10"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onAccountsTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('contacts').visible"
      >
        <div key="contact-results" v-show="contacts.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="contacts.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-contacts
                style="display: inline;cursor: pointer"
              >
                Contacts
                <span v-if="contacts.count > 0">({{ contacts.count }})</span>
              </h5>
            </b-col>
          </b-row>
          <b-collapse visible id="collapse-contacts" v-model="contacts.visible">
            <b-row>
              <b-col>
                <contacts-table
                  ref="contacts-table"
                  :per-page="10"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onContactsTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('invoices').visible"
      >
        <div key="invoices-results" v-show="invoices.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="invoices.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-invoices
                style="display: inline;cursor: pointer"
              >
                Invoices
                <span v-if="invoices.count > 0">({{ invoices.count }})</span>
              </h5>
            </b-col>
          </b-row>
          <b-collapse visible id="collapse-invoices" v-model="invoices.visible">
            <b-row>
              <b-col>
                <invoices-table
                  ref="invoices-table"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onInvoicesTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('activities-all').visible"
      >
        <div key="activities-results" v-show="activities.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="activities.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-activities
                style="display: inline;cursor: pointer"
              >
                Activities
                <span v-if="activities.count > 0"
                  >({{ activities.count }})</span
                >
              </h5>
            </b-col>
          </b-row>
          <b-collapse
            visible
            id="collapse-activities"
            v-model="activities.visible"
          >
            <b-row>
              <b-col>
                <activities-table
                  ref="activities-table"
                  mode="all"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onActivitiesTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('activities-my').visible"
      >
        <div key="activities-results" v-show="activities.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="activities.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-activities
                style="display: inline;cursor: pointer"
              >
                Activities
                <span v-if="activities.count > 0"
                  >({{ activities.count }})</span
                >
              </h5>
            </b-col>
          </b-row>
          <b-collapse
            visible
            id="collapse-activities"
            v-model="activities.visible"
          >
            <b-row>
              <b-col>
                <activities-table
                  ref="activities-table"
                  mode="my"
                  :show-add-button="false"
                  @loaded="onActivitiesTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('sample-requests').visible"
      >
        <div key="sample-requests-results" v-show="sampleRequests.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="sampleRequests.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-sample-requests
                style="display: inline;cursor: pointer"
              >
                Sample requests
                <span v-if="sampleRequests.count > 0"
                  >({{ sampleRequests.count }})</span
                >
              </h5>
            </b-col>
          </b-row>
          <b-collapse
            visible
            id="collapse-sample-requests"
            v-model="sampleRequests.visible"
          >
            <b-row>
              <b-col>
                <sample-requests-table
                  ref="sample-requests-table"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onSampleRequestsTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('projects').visible"
      >
        <div key="projects-results" v-show="projects.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="projects.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-projects
                style="display: inline;cursor: pointer"
              >
                Projects
                <span v-if="projects.count > 0">({{ projects.count }})</span>
              </h5>
            </b-col>
          </b-row>
          <b-collapse visible id="collapse-projects" v-model="projects.visible">
            <b-row>
              <b-col>
                <projects-table
                  ref="projects-table"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onProjectsTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>

          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('tasks').visible"
      >
        <div key="tasks-results" v-show="tasks.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="tasks.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-tasks
                style="display: inline;cursor: pointer"
              >
                Tasks <span v-if="tasks.count > 0">({{ tasks.count }})</span>
              </h5>
            </b-col>
          </b-row>
          <b-collapse visible id="collapse-tasks" v-model="tasks.visible">
            <b-row>
              <b-col>
                <tasks-table
                  ref="tasks-table"
                  :autoload="false"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onTasksTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('reports-all').visible"
      >
        <div key="reports-results" v-show="reports.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="reports.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-reports
                style="display: inline;cursor: pointer"
              >
                Reports
                <span v-if="reports.count > 0">({{ reports.count }})</span>
              </h5>
            </b-col>
          </b-row>
          <b-collapse visible id="collapse-reports" v-model="reports.visible">
            <b-row>
              <b-col>
                <reports-table
                  ref="reports-table"
                  filter="all"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onReportsTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>

      <transition-group
        name="slide"
        :duration="slideDuration"
        v-if="$permitted('reports-my').visible"
      >
        <div key="reports-results" v-show="reports.count !== 0">
          <b-row class="p-1">
            <b-col cols="4">
              <font-awesome-icon
                :icon="reports.visible ? 'chevron-up' : 'chevron-down'"
              />
              <h5
                v-b-toggle.collapse-reports
                style="display: inline;cursor: pointer"
              >
                Reports
                <span v-if="reports.count > 0">({{ reports.count }})</span>
              </h5>
            </b-col>
          </b-row>
          <b-collapse visible id="collapse-reports" v-model="reports.visible">
            <b-row>
              <b-col>
                <reports-table
                  ref="reports-table"
                  filter="my"
                  :show-add-button="false"
                  :module="$constants.MODULES.GlobalSearch"
                  @loaded="onReportsTableLoad"
                />
              </b-col>
            </b-row>
          </b-collapse>
          <hr />
        </div>
      </transition-group>
    </b-card>
  </div>
</template>

<script>
import AccountsTable from "@/views/RelationshipManagement/AccountsTable";
import ContactsTable from "@/views/RelationshipManagement/ContactsTable";
import InvoicesTable from "@/views/SalesAdministrative/InvoicesTable";
import ActivitiesTable from "@/views/AccountTouchpoints/ActivitiesTable";
import SampleRequestsTable from "@/views/PosAndSamples/SampleRequestsTable";
import ProjectsTable from "@/views/ProjectManagement/ProjectsTable";
import TasksTable from "@/views/ProjectManagement/TasksTable";
import ReportsTable from "@/views/Reports/ReportsTable";

export default {
  props: {
    query: {
      type: [String, Number],
      default: ""
    }
  },
  name: "SearchResults",
  components: {
    AccountsTable,
    ContactsTable,
    InvoicesTable,
    ActivitiesTable,
    SampleRequestsTable,
    ProjectsTable,
    TasksTable,
    ReportsTable
  },
  data: function() {
    return {
      totalResultsCount: undefined,
      slideDuration: 300,
      accounts: {
        visible: true,
        count: undefined
      },
      contacts: {
        visible: true,
        count: undefined
      },
      invoices: {
        visible: true,
        count: undefined
      },
      activities: {
        visible: true,
        count: undefined
      },
      sampleRequests: {
        visible: true,
        count: undefined
      },
      projects: {
        visible: true,
        count: undefined
      },
      tasks: {
        visible: true,
        count: undefined
      },
      reports: {
        visible: true,
        count: undefined
      }
    };
  },
  computed: {},
  mounted() {
    if (this.query.trim() === "") return;

    let searchFunc = [];

    //console.log('this.$permitted(accounts)',this.$permitted('accounts'))

    if (this.$permitted("accounts").visible)
      searchFunc.push(this.findAccounts());

    if (this.$permitted("contacts").visible)
      searchFunc.push(this.findContacts());

    if (this.$permitted("invoices").visible)
      searchFunc.push(this.findInvoices());

    if (this.$permitted("activities-all").visible)
      searchFunc.push(this.findActivities());

    if (this.$permitted("activities-my").visible)
      searchFunc.push(this.findActivities());

    if (this.$permitted("sample-requests").visible)
      searchFunc.push(this.findSampleRequests());

    if (this.$permitted("projects").visible)
      searchFunc.push(this.findProjects());

    if (this.$permitted("tasks").visible) searchFunc.push(this.findTasks());

    if (this.$permitted("reports-all").visible)
      searchFunc.push(this.findReports());

    if (this.$permitted("reports-my").visible)
      searchFunc.push(this.findReports());

    Promise.all(searchFunc).then(() => {
      this.totalResultsCount =
        (this.accounts.count || 0) +
        (this.contacts.count || 0) +
        (this.invoices.count || 0) +
        (this.activities.count || 0) +
        (this.sampleRequests.count || 0) +
        (this.projects.count || 0) +
        (this.tasks.count || 0) +
        (this.reports.count || 0);

      console.log("search completed", this.totalResultsCount);
    });
  },
  methods: {
    async findAccounts() {
      let payload = {};

      let rules = [];

      let account_name = {
        field: "account",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let account_description = {
        field: "account_description",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(account_name);
      rules.push(account_description);

      payload.expression = JSON.stringify(rules);

      await this.$refs["accounts-table"].getData(payload);
    },
    async findContacts() {
      let payload = {};

      let rules = [];

      let contact_name = {
        field: "contact_full_name",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let contact_description = {
        field: "contact_description",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let account_name = {
        field: "contact_account_name",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(contact_name);
      rules.push(contact_description);
      rules.push(account_name);

      payload.expression = JSON.stringify(rules);

      await this.$refs["contacts-table"].getData(payload);
    },
    async findInvoices() {
      let payload = {};

      let rules = [];

      let invoice_no = {
        field: "invoice_no",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let account_name = {
        field: "account",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(invoice_no);
      rules.push(account_name);

      payload.expression = JSON.stringify(rules);

      await this.$refs["invoices-table"].getData(payload);
    },

    async findActivities() {
      let payload = {};

      let rules = [];

      let subject = {
        field: "activity_subject",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };
      let description = {
        field: "activity_description",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };
      let account = {
        field: "activity_account",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };
      let contact = {
        field: "activity_contact",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(subject);
      rules.push(description);
      rules.push(account);
      rules.push(contact);

      payload.expression = JSON.stringify(rules);

      await this.$refs["activities-table"].getData(payload);
    },

    async findSampleRequests() {
      let payload = {};

      let rules = [];

      let content = {
        field: "sample_request_notes",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };
      let account = {
        field: "sample_request_account",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };
      let distributor = {
        field: "sample_request_distributor",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let contact = {
        field: "sample_request_contact",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(content);
      rules.push(account);
      rules.push(distributor);
      rules.push(contact);

      payload.expression = JSON.stringify(rules);

      await this.$refs["sample-requests-table"].getData(payload);
    },

    async findProjects() {
      let payload = {};

      let rules = [];

      let subject = {
        field: "project_subject",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let description = {
        field: "project_description",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let account = {
        field: "project_account",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(subject);
      rules.push(description);
      rules.push(account);

      payload.expression = JSON.stringify(rules);

      await this.$refs["projects-table"].getData(payload);
    },

    async findTasks() {
      let payload = {};

      let rules = [];

      let subject = {
        field: "task_subject",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let description = {
        field: "task_description",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let account = {
        field: "task_account",
        field_logic: "Any",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(subject);
      rules.push(description);
      rules.push(account);

      payload.expression = JSON.stringify(rules);

      await this.$refs["tasks-table"].getData(payload);
    },
    async findReports() {
      let payload = {};

      let rules = [];

      let name = {
        field: "report_name",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      let content = {
        field: "report_content",
        type: "string",
        logic: "All",
        rules: [
          {
            logicalOperator: "Contains",
            selectedValues: [],
            selectedValue: this.query
          }
        ]
      };

      rules.push(name);
      rules.push(content);

      payload.expression = JSON.stringify(rules);

      await this.$refs["reports-table"].getData(payload);
    },

    onAccountsTableLoad(_count) {
      this.accounts.count = _count;
    },
    onContactsTableLoad(_count) {
      this.contacts.count = _count;
    },
    onInvoicesTableLoad(_count) {
      this.invoices.count = _count;
    },
    onActivitiesTableLoad(_count) {
      this.activities.count = _count;
    },
    onSampleRequestsTableLoad(_count) {
      this.sampleRequests.count = _count;
    },
    onProjectsTableLoad(_count) {
      this.projects.count = _count;
    },
    onTasksTableLoad(_count) {
      this.tasks.count = _count;
    },
    onReportsTableLoad(_count) {
      this.reports.count = _count;
    }
  }
};
</script>

<style>
/*
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
{
  transform: translateX(10px);
  opacity: 0;
}
*/
.slide-leave-active,
.slide-enter-active {
  transition: 2s;
}

.slide-enter {
  /* transform: translate(100%, 0);*/
  transform: translate(-100%, 0);
}

.slide-leave-to {
  /*transform: translate(-100%, 0);*/
  transform: translate(100%, 0);
}
</style>
