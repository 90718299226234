var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', [_vm._v(" Search phrase: "), _c('strong', [_vm._v(_vm._s(_vm.query))])])], 1), _vm.totalResultsCount === 0 ? _c('b-row', {
    staticClass: "p-1 text-center"
  }, [_c('b-col', {
    staticClass: "p-3"
  }, [_vm._v(" No data available ")])], 1) : _vm._e(), _vm.$permitted('accounts').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.accounts.count !== 0,
      expression: "accounts.count !== 0"
    }],
    key: "accounts-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.accounts.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-accounts",
      modifiers: {
        "collapse-accounts": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Accounts "), _vm.accounts.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.accounts.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-accounts"
    },
    model: {
      value: _vm.accounts.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.accounts, "visible", $$v);
      },
      expression: "accounts.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('accounts-table', {
    ref: "accounts-table",
    attrs: {
      "per-page": 10,
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onAccountsTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('contacts').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.contacts.count !== 0,
      expression: "contacts.count !== 0"
    }],
    key: "contact-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.contacts.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-contacts",
      modifiers: {
        "collapse-contacts": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Contacts "), _vm.contacts.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.contacts.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-contacts"
    },
    model: {
      value: _vm.contacts.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.contacts, "visible", $$v);
      },
      expression: "contacts.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('contacts-table', {
    ref: "contacts-table",
    attrs: {
      "per-page": 10,
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onContactsTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('invoices').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.invoices.count !== 0,
      expression: "invoices.count !== 0"
    }],
    key: "invoices-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.invoices.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-invoices",
      modifiers: {
        "collapse-invoices": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Invoices "), _vm.invoices.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.invoices.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-invoices"
    },
    model: {
      value: _vm.invoices.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.invoices, "visible", $$v);
      },
      expression: "invoices.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('invoices-table', {
    ref: "invoices-table",
    attrs: {
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onInvoicesTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('activities-all').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activities.count !== 0,
      expression: "activities.count !== 0"
    }],
    key: "activities-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.activities.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-activities",
      modifiers: {
        "collapse-activities": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Activities "), _vm.activities.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.activities.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-activities"
    },
    model: {
      value: _vm.activities.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.activities, "visible", $$v);
      },
      expression: "activities.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('activities-table', {
    ref: "activities-table",
    attrs: {
      "mode": "all",
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onActivitiesTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('activities-my').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activities.count !== 0,
      expression: "activities.count !== 0"
    }],
    key: "activities-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.activities.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-activities",
      modifiers: {
        "collapse-activities": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Activities "), _vm.activities.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.activities.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-activities"
    },
    model: {
      value: _vm.activities.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.activities, "visible", $$v);
      },
      expression: "activities.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('activities-table', {
    ref: "activities-table",
    attrs: {
      "mode": "my",
      "show-add-button": false
    },
    on: {
      "loaded": _vm.onActivitiesTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('sample-requests').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.sampleRequests.count !== 0,
      expression: "sampleRequests.count !== 0"
    }],
    key: "sample-requests-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.sampleRequests.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-sample-requests",
      modifiers: {
        "collapse-sample-requests": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Sample requests "), _vm.sampleRequests.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.sampleRequests.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-sample-requests"
    },
    model: {
      value: _vm.sampleRequests.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.sampleRequests, "visible", $$v);
      },
      expression: "sampleRequests.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('sample-requests-table', {
    ref: "sample-requests-table",
    attrs: {
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onSampleRequestsTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('projects').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.projects.count !== 0,
      expression: "projects.count !== 0"
    }],
    key: "projects-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.projects.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-projects",
      modifiers: {
        "collapse-projects": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Projects "), _vm.projects.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.projects.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-projects"
    },
    model: {
      value: _vm.projects.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.projects, "visible", $$v);
      },
      expression: "projects.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('projects-table', {
    ref: "projects-table",
    attrs: {
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onProjectsTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('tasks').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.tasks.count !== 0,
      expression: "tasks.count !== 0"
    }],
    key: "tasks-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.tasks.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-tasks",
      modifiers: {
        "collapse-tasks": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Tasks "), _vm.tasks.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.tasks.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-tasks"
    },
    model: {
      value: _vm.tasks.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.tasks, "visible", $$v);
      },
      expression: "tasks.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('tasks-table', {
    ref: "tasks-table",
    attrs: {
      "autoload": false,
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onTasksTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('reports-all').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.reports.count !== 0,
      expression: "reports.count !== 0"
    }],
    key: "reports-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.reports.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-reports",
      modifiers: {
        "collapse-reports": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Reports "), _vm.reports.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.reports.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-reports"
    },
    model: {
      value: _vm.reports.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.reports, "visible", $$v);
      },
      expression: "reports.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('reports-table', {
    ref: "reports-table",
    attrs: {
      "filter": "all",
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onReportsTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e(), _vm.$permitted('reports-my').visible ? _c('transition-group', {
    attrs: {
      "name": "slide",
      "duration": _vm.slideDuration
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.reports.count !== 0,
      expression: "reports.count !== 0"
    }],
    key: "reports-results"
  }, [_c('b-row', {
    staticClass: "p-1"
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": _vm.reports.visible ? 'chevron-up' : 'chevron-down'
    }
  }), _c('h5', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-reports",
      modifiers: {
        "collapse-reports": true
      }
    }],
    staticStyle: {
      "display": "inline",
      "cursor": "pointer"
    }
  }, [_vm._v(" Reports "), _vm.reports.count > 0 ? _c('span', [_vm._v("(" + _vm._s(_vm.reports.count) + ")")]) : _vm._e()])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": "",
      "id": "collapse-reports"
    },
    model: {
      value: _vm.reports.visible,
      callback: function callback($$v) {
        _vm.$set(_vm.reports, "visible", $$v);
      },
      expression: "reports.visible"
    }
  }, [_c('b-row', [_c('b-col', [_c('reports-table', {
    ref: "reports-table",
    attrs: {
      "filter": "my",
      "show-add-button": false,
      "module": _vm.$constants.MODULES.GlobalSearch
    },
    on: {
      "loaded": _vm.onReportsTableLoad
    }
  })], 1)], 1)], 1), _c('hr')], 1)]) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }