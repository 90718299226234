<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @view-item="viewItem"
      @edit-item="editItem"
      @delete-item="deleteItem"
      @filter="onFilter"
    >
      <div slot="afterFilter">
        <b-row v-if="dataTable.visible && showAddButton">
          <b-col>
            <b-button variant="outline-dark" title="Create" @click="addItem()">
              <font-awesome-icon icon="plus" /> Create
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <span class="btn btn-primary btn-sm" @click="viewItem(props.row.ID)">
            <font-awesome-icon icon="eye" />
          </span>
          <span
            v-if="
              (props.row.Reviewed === 'Yes' && isReviewer) ||
                props.row.Reviewed === 'No'
            "
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt"
          /></span>
          <span
            v-if="
              (props.row.Reviewed === 'Yes' && isReviewer) ||
                props.row.Reviewed === 'No'
            "
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
          >
            <font-awesome-icon icon="trash"
          /></span>
        </div>
      </div>
      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="$route.meta.module.id"
          :entity-id="props.row['ID']"
        />
      </span>
    </table-custom>
  </div>
</template>

<script>
import FilesContainer from "@/components/FilesContainer";
import TableCustom from "@/components/TableCustom";

export default {
  name: "SampleRequestsTable",
  props: {
    showAddButton: {
      type: Boolean,
      default: true
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  components: {
    FilesContainer,
    TableCustom
  },
  data: function() {
    return {
      isReviewer: false,
      rawData: {},
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          columns: [
            "ID",
            "Requested By",
            "Request Date",
            "Date Needed",
            "Reason",
            "Notes",
            "Barrel Sample",
            "Delivery Type",
            "Ship Name",
            "Ship Phone",

            "Ship Street",
            "Ship City",
            "Ship State",
            "Ship Zip",
            "Reviewed",
            "Delivered",
            //   'Created',
            //   'Modified',
            "Actions"
          ],
          cellClasses: {
            Reviewed: [
              {
                class: "bg-warning",
                condition: row => row["Reviewed"] === "No"
              }
            ],
            Delivered: [
              {
                class: "bg-warning",
                condition: row => row["Delivered"] === "No"
              }
            ],
            "Barrel Sample": [
              {
                class: "bg-warning",
                condition: row => row["Barrel Sample"] === "Yes"
              }
            ]
          },

          perPage: 50,
          disablePerPageDropdown: false,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: true
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async getData(payload) {
      let self = this;

      this.isReviewer = await this.$api.get("sample-requests/reviewer");

      //display spinner  by default
      if (!payload || !payload.silentUpdate) {
        self.dataTable.isLoading = true;
        self.dataTable.dataSet = [];
      }

      return this.$api
        .post("sample-requests", payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          self.dataTable.dataSet = response;

          if (response.length === 0) return;

          //20210131 #316 global search
          //self.$emit("loaded", self.dataTable.dataSet.length)
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    toggleFiles(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },

    addItem: function() {
      this.$router.push({
        name: "Sample request submission",
        params: {
          action: "create"
        }
      });
    },

    viewItem: function(id) {
      this.$router.push({
        name: "Sample request submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Sample request submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let request = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Sample request #${request.ID} will be deleted, selected barrels deselected. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`sample-requests/${request.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData({
            silentUpdate: true
          });
        })
        .catch(response => {
          this.dataTable.isLoading = false;

          console.log(response);

          self.$form.makeToastError(response.message);
        });
    }
  }
};
</script>

<style scoped></style>
